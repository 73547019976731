import { useState, useEffect } from "react";
import { questionApi }  from "../api/questionApi";

export const useQuestions = () => {

    const [ questions, setQuestions ] = useState([]);
    const [ refresh, setRefresh ] = useState(false);

    const fetchQuestions = async () => {
        const localQuestions = localStorage.getItem("questions");
        const expirationQuestions = localStorage.getItem("questionsTimestamp");
        
        if(localQuestions && expirationQuestions && new Date(expirationQuestions) > new Date()){
            setQuestions(JSON.parse(localQuestions));
            return;
        }

        const { getQuestions } = questionApi();
        const { data, statusCode } = await getQuestions();
        if(statusCode === 200 ){
            const sortedQuestions = data.data.sort((a, b) => a.number - b.number);
            setQuestions(sortedQuestions);
            localStorage.setItem("questions", JSON.stringify(sortedQuestions));
            localStorage.setItem("questionsTimestamp", new Date(new Date().getTime() + 1000 * 60 * 60 * 24).toISOString());
        }
    }

    const refreshQuestions = () => {
        setRefresh(!refresh);
    }

    useEffect(() => {
        fetchQuestions();
    }, [refresh]);

    return {
        questions,
        refreshQuestions
    }  
}
