import { post, get } from 'aws-amplify/api';
import { fetchAuthSession } from "aws-amplify/auth";

export const answersApi = () => {

  const postAnswer = async(answersBody) => {

    const authSession = await fetchAuthSession();
    const jwtToken = authSession.tokens?.idToken?.toString()
    const history = authSession.tokens.idToken?.payload["nickname"];

    answersBody["user"] = history;
    if(answersBody.answers && answersBody.answers.length > 0){
      const answers = answersBody.answers.map((answer) => {
        return { "S": String(answer) }
      })
      answersBody.answers = answers;
    }

    try {
      const restOperation = post({
        apiName: 'StressRepo',
        path: '/public/data',
        options: {
          body: answersBody,
          headers: {
            'Authorization': 'Bearer ' + jwtToken,
            'Content-Type': 'application/json'
          }
        }
      });

      const { body, statusCode } = await restOperation.response;
      if(statusCode === 200){
        return { undefined, statusCode }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getAnswers = async() => {
    const authSession = await fetchAuthSession();
    const jwtToken = authSession.tokens?.idToken?.toString()
    const history = authSession.tokens.idToken?.payload["nickname"];

    try {
      const restOperation = get({
        apiName: 'StressRepo',
        path: '/public/data',
        options: {

          headers: {
            'user': history,
            'Authorization': 'Bearer ' + jwtToken
          }
        }
      });

      const { body, statusCode } = await restOperation.response;
      if(statusCode === 200){
        const data = await body.json();
        return { data, statusCode }
      }
    } catch (e) {
      console.log(e);
    }
  }
  

  return {
    getAnswers,
    postAnswer
  }
}