import { createTheme } from '@mui/material/styles';
import { red, common } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#673ab7',
    },
    secondary: {
      main: '#E7F1F1',
    },
    // palette3: '#F8F37C',

    // palette4: '#313131',

    // palette5: '#555555',

    // palette6: '#718096',

    // palette7: '#EDF2F7',

    // palette8: '#F7FAFC',

    // palette9: '#FFFFFF',
    common: {
      white: common.white,
    },

    action: { 
      hover: '#f6f2ef',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;