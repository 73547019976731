import React, { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../hooks/useUser";

import { CircularProgress, Typography } from "@mui/material";
import { Button, TextField } from "@mui/material";
import { PageLayout } from "../layout";

import { signOut } from "aws-amplify/auth";
import { useQuestions } from "../hooks/useQuestions";

const Home = () => {
  const navigate = useNavigate();
  const { questions } = useQuestions();
  const { userSub, userStep, updateStep, updateHistory } = useUser();
  const [ history, setHistory ] = useState("");
  const [ error, setError ] = useState(false);

  useEffect(() => {
    //Si se ha cargado el usuario, hay preguntas y el usuario no ha contestado ninguna pregunta, establecer a 1 el paso
    console.log("userSub", userSub);
    console.log("userStep", userStep);
    console.log("questions", questions);
    if (userSub !== undefined && questions.length > 0 && userStep === 0 ) {
      updateStep(1, 1);
    }
  }, [userSub, userStep, questions]);

  const partialregex = /^HC-/;
  const regex = /^HC-\d{3}$/;

  const handleChange = (event) => {
    const newValue = event.target.value;

    if (regex.test(newValue) || newValue.length < 6 ) {
      setError(false); // Si cumple, no hay error
    } else {
      setError(true);  // Si no cumple, muestra error
    }

    if (newValue.length > 2 && !partialregex.test(newValue)){
      setError(true);
    }

    // Actualiza el valor del campo
    setHistory(newValue);
  };

  return (
    <PageLayout>
      {userStep < 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          Cargando, por favor espere
        </div>
      )}

      {userStep === 0 && userSub == undefined && (
        <>
          <p>
            Antes de comenzar, debe introducir el código del estudio proporcionado por el personal medico.

            Este codigo tiene un formato del estilo HC-000
          </p>

          <TextField
            id="standard-basic"
            label="Codigo de estudio"
            variant="standard"
            fullWidth
            inputProps={{ maxLength: 6 }} 
            value={ history }
            onChange={ handleChange }
            error={ error }
            helperText={ error ? 'El codigo debe tener el formato HC-000' : '' }
          />

          <Button
            variant="contained"
            fullWidth
            disabled={error || history.length !== 6 }
            sx={{ mt: 1 }}
            onClick={() => {
              updateHistory(history)
            }}
          >
            Guardar
          </Button>
        </>
      )}
      {userStep > 0 && (
        <Typography sx={{ mt: 2, mb: 2 }} align="justify">
          Bienvenida a su participación en el estudio. A continuación, se
          presentan enumerados los diferentes momentos del proceso en los que
          deberá rellenar los cuestionarios pertinentes. Aparece marcado en morado
          intenso el momento del proceso en el que creemos que se encuentra.
          <br />
          <br />
          En caso de error, haga click en el momento en el que usted considere
          que se encuentre actualmente, es decir, señale el motivo por el que
          acude hoy a este centro.
        </Typography>
      )}
      {userStep > 0 &&
        questions.map(
          (question, index) =>
            index > 0 && (
              <Button
                key={index}
                variant="contained"
                color={
                  Number(index) === Number(userStep) ? "primary" : "secondary"
                }
                fullWidth
                sx={{ mt: 1 }}
                onClick={() => {
                  navigate("/visit/" + index);
                }}
              >
                {"" + index + " - " + question.name}
              </Button>
            )
        )}

      <Button
        variant="contained"
        fullWidth
        color="warning"
        sx={{ mt: 2 }}
        onClick={() => {
          signOut();
        }}
      >
        Salir
      </Button>
    </PageLayout>
  );
};

export default Home;
