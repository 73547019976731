import React, { useEffect, useState } from "react";
import { useUser } from "../hooks/useUser";
import { CircularProgress } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useQuestions } from "../hooks/useQuestions";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { IframeLayout } from "../layout/IframeLayout";

function Questions() {
  const navigate = useNavigate();

  const { step, id } = useParams();
  const { questions } = useQuestions();
  const { userSub, userCycle, answers, fetchUserData } = useUser();

  const [formId, setFormId] = useState(undefined);
  const [form, setForm] = useState([]);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (questions.length === 0) return;
    const base = questions[step].answers[id].S;
    const baseS = base.split(":::")[2];
    setFormId(base.split(":::")[1]);
    setForm(
      baseS
        .replace("{history}", userSub)
        .replace("{visit}", step)
        .replace("{cycle}", userCycle)
    );
  }, [questions, id, step, userCycle, userSub]);

  useEffect(() => {
    if (answers.includes(formId)) {
      navigate("/visit/" + step);
    }
  }, [answers, formId, step, navigate]);

  const formEffect = (e) => {
    setTimeout(() => {
      for (let i = 0; i < 7; i++) {
        setTimeout(() => {
          fetchUserData();
        }, 1000 * i);
      }
    }, 3300);
  };

  return (
    <IframeLayout>
      {!questions && questions.length < 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          Cargando, por favor espere
        </div>
      )}
      {questions.length > 0 && (
        <iframe
          title="questionnaire"
          src={form + "&embedded=true"}
          marginheight={0}
          marginwidth={0}
          width={width}
          height={height - 100}
          onLoad={(e) => {
            formEffect(e);
          }}
        >
          Cargando…
        </iframe>
      )}
    </IframeLayout>
  );
}

export default Questions;
