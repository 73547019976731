import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { answersApi }  from "../api/answerApi";
import { fetchAuthSession, updateUserAttributes } from 'aws-amplify/auth';

export const useUser = () => {
    
    const navigate = useNavigate();
    const [data, setData] = useState(undefined);
    const [userSub, setUserSub] = useState(undefined);
    const [userStep, setUserStep] = useState(-1);
    const [userCycle, setUserCycle] = useState(1);
    const [answers, setAnswers] = useState([]);

    const fetchUserData = async () => {
      try {
        const { getAnswers } = answersApi();
        const { tokens } = await fetchAuthSession();
        const history = tokens.idToken.payload["nickname"];
        setUserSub(history)

        const { data, statusCode } = await getAnswers();
        if(statusCode === 200){
          if(data.data.length > 0){
            setUserStep(data.data[0].step);
            setUserCycle(data.data[0].cycle);
            setData(data.data[0])
            const values = data.data[0].answers.map(obj => obj.S);
            setAnswers(values);
          } else {
            setUserStep(0);
          }
        }
      } catch (error) {
        console.error('Error al obtener el usuario actual:', error);
      }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const updateStep = async (cycle, step) => {
        const { postAnswer } = answersApi();
        const answerBody = {
          "date": new Date().toISOString(),
          "cycle": cycle,
          "step": step,
          "answers": []
        }
        const {data, statusCode} = await postAnswer(answerBody);

        if(statusCode === 200 ){
          setUserStep(step);
        }
    }

    const updateHistory = async (history) => {
      const response = await updateUserAttributes({
        userAttributes: {
          "nickname": history
        }
      });
      
      if(response.nickname.isUpdated){
        setUserSub(history)
      }
    }

    return {
        data,
        userSub,
        userStep,
        userCycle,
        answers,
        updateStep,
        fetchUserData,
        updateHistory
    }  
}