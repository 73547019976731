import { Toolbar } from '@mui/material'
import { Box } from '@mui/system'

export const IframeLayout = ({children}) => {
  return (
        <Box 
            sx={{ display: 'flex' }}
        >
            <Box 
                component = 'main'
                sx = {{ flexGrow: 1
                }}
            >
                <Toolbar sx={{ backgroundColor: 'primary.main', display: 'flex', justifyContent: 'center' }}>
                    <h1 style={{ color: 'white' }}>Estudio sobre el estrés en los tratamientos reproductivos</h1>
                </Toolbar>
                {children}
            </Box>
        </Box>
    )
}
