import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import '@aws-amplify/ui-react/styles.css';
import './index.css';
import App from './App';

import { Amplify } from 'aws-amplify';
import { I18n } from 'aws-amplify/utils';

import awsExports from './aws-exports';
Amplify.configure(awsExports);


const dict = {
  es: {
    'Sign In': 'Iniciar sesión',
    'Sign in': 'Iniciar sesión',
    'Sign In with Google': 'Iniciar sesión con Google',
    'Sign In with Facebook': 'Iniciar sesión con Facebook',
    'Sign Up': 'Registrarse',
    'Sign Up with Google': 'Registrate con Google',
    'Sign Up with Facebook': 'Registrate con Facebook',
    'Sign Out': 'Cerrar sesión',
    'or': 'o',
    'Create Account': 'Crear cuenta',
    'Username': 'Correo electronico',
    'Enter your Username': 'Introduce tu correo electronico',
    'Password': 'Contraseña',
    'Enter your Password': 'Introduce tu contraseña',
    'Forgot your password?': '¿Olvidaste tu contraseña?',
    'Reset password': 'Restablecer contraseña',
    'Please confirm your Password': 'Por favor confirma tu contraseña',
    'Send Code': 'Enviar código',
    'Back to Sign In': 'Volver a iniciar sesión',
    'Change Password': 'Cambiar contraseña',
    'New password': 'Nueva contraseña',
    'Confirm Password': 'Confirmar contraseña',
    'Name': 'Nombre',
    'Family Name': 'Apellido',
    'Enter your Name': 'Introduce tu nombre',
    'Enter your Family Name': 'Introduce tu apellido',
    'Nickname': 'Codigo personal de estudio',
    'Enter your Nickname': 'Introduce el codigo con formato HC-000',
    'We Sent A Code': 'Te hemos enviado un código',
    'User does not exist.': 'El usuario no existe.',
  }
};

I18n.putVocabularies(dict);
I18n.setLanguage('es');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
      <App />
     </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
