
import { Route, Routes } from 'react-router-dom'

import Home from '../pages/Home';
import Question from '../pages/Questions';
import Visit from '../pages/Visit';
import Disclaimer from '../pages/Disclaimer';

function AppRouter() {
    return (
      <Routes>
        <Route path="/visit/:visit" element={<Visit />} />
        <Route path="/questions/:step/:id" element={<Question />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="*" element={<Home />} />
      </Routes>
    );
  }
  
  export default AppRouter;
  