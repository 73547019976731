import React from 'react';
import { PageLayout } from '../layout';

import {
  Button,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";

function Disclaimer() {

  const { userSub, updateStep } = useUser();
  const navigate = useNavigate();

  return (
    <PageLayout>
      <h1>Disclaimer!</h1>
      <p>aaaaa.</p>

      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => {
          updateStep(1,1);
        }}
      >
        Aceptar y continuar
      </Button>

      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => {
          navigate("/");
        }}
      >
        Volver
      </Button>
    </PageLayout>
  );
}

export default Disclaimer;