

const awsExports = {
    "aws_project_region": "eu-south-2",
    "aws_cognito_region": "eu-south-2",
    "aws_user_pools_id": "eu-south-2_bX1ZrBT4S",
    "aws_user_pools_web_client_id": "2orgllcmon5c05ce2oaqdhqps5",
    "aws_cognito_social_providers": [
        "GOOGLE", "FACEBOOK"
    ],
    "oauth": {
        "domain": "stress-repo.auth.eu-south-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://estresreproductivo.com/",
        "redirectSignOut": "https://estresreproductivo.com/",
        "responseType": "code"
    },
    "aws_cloud_logic_custom": [
            {
                "name": "StressRepo",
                "endpoint": "https://z389g1ltwj.execute-api.eu-south-2.amazonaws.com/v1/",
                "region": "eu-south-2",
            }
        ]
};

//https://z389g1ltwj.execute-api.eu-south-2.amazonaws.com/v1/
// lcp --proxyUrl https://z389g1ltwj.execute-api.eu-south-2.amazonaws.com
// http://localhost:8010/proxy/v1
export default awsExports;