import './App.css';

import AppRouter from './router/AppRouter';
import { AppTheme } from './theme/AppTheme';

import { withAuthenticator } from '@aws-amplify/ui-react';

const formFields = {
  signUp: {
      nickname: {
        order: 3,
        required: true,
        validate: (value) => {
          const codeRegex = /^HC-\d{3}$/; // Expresión regular para "HC-000"
          if (!codeRegex.test(value)) {
            return 'El código debe seguir el formato HC-000, donde 000 son números.';
          }
          return true;
        }
      },
      username: {
        order:4,
        required: true
      },
      password: {
          order: 5
      },
      confirm_password: {
          order: 6
      }
  },
}

function App() {
  return (
    <AppTheme>
      <AppRouter></AppRouter>
    </AppTheme>
  );
}
export default withAuthenticator(App, {formFields});
