import { get } from 'aws-amplify/api';
import { fetchAuthSession } from "aws-amplify/auth";

export const questionApi = () => {

  const getQuestions = async() => {
    const jwtToken = ( await fetchAuthSession()).tokens?.idToken?.toString()
    try {
      const restOperation = get({
        apiName: 'StressRepo',
        path: '/public/questions',
        options: {
          headers: {
            'Authorization': 'Bearer ' + jwtToken
          }
        }
      });

      const { body, statusCode } = await restOperation.response;
      if(statusCode === 200){
        const data = await body.json();
        return { data, statusCode }
      }
    } catch (e) {
      console.log(e);
    }
  }
  

  return {
    getQuestions
  }
}