import React, { useEffect, useState } from "react";
import { useUser } from "../hooks/useUser";

import { PageLayout } from "../layout";
import { Button, CircularProgress, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useQuestions } from "../hooks/useQuestions";

function Visit() {
  const navigate = useNavigate();

  const { visit } = useParams();
  const { questions } = useQuestions();
  const { answers, data, updateStep } = useUser();
  const [step, setStep] = useState(undefined);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    if (questions.length === 0 || data === undefined) return;
    if (Number(data.step) === Number(visit)) {
      setStep(questions[visit]);
    } else {
      setWarning(true);
    }
  }, [questions, visit, data]);

  useEffect(() => {
    if (data && questions) {
      questions.forEach((question, index) => {
        if (Number(question.number) === Number(data.step)) {
          if (question.answers.length === data.answers.length) {
            updateStep(data.cycle, Number(data.step) + 1);
            navigate("/");
          }
        }
      });
    }
  }, [questions, data, navigate, updateStep]);

  return (
    <PageLayout>
      {!step && !warning && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          Cargando, por favor espere
        </div>
      )}

      {warning && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <Typography
            sx={{ mt: 2 }}
            variant="h5"
            style={{ textAlign: "center" }}
          >
            Visita {questions[visit].number} - {questions[visit].name}
          </Typography>
          <br />
          <Typography
            variant="h6"
            color="error"
            style={{ textAlign: "center" }}
          >
            {" "}
            ¡Advertencia!{" "}
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            Pensamos que esta no es la visita que te toca. La visita que te
            corresponde es: <br />
            <b>
              {" "}
              {questions[data.step].number} - {questions[data.step].name}{" "}
            </b>{" "}
          </Typography>
          <br />
          <Typography style={{ textAlign: "center" }}>
            Si te has saltado alguna visita anterior y no has rellenado el
            formulario, o se ha producido algun error y es esta la que te toca
            confirma en el siguiente botón
          </Typography>
          <Button
            variant="contained"
            color="error"
            fullWidth
            sx={{ mt: 1 }}
            onClick={() => {
              updateStep(data.cycle, visit);
              navigate(0);
            }}
          >
            Reestablecer visita
          </Button>
        </div>
      )}
      {step && (
        <>
          <Typography
            sx={{ mt: 2 }}
            variant="h5"
            style={{ textAlign: "center" }}
          >
            Visita {visit} - {step.name}
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            {step.description}
          </Typography>
        </>
      )}
      {step &&
        step.answers.map((answer, index) => (
          <Button
            key={index}
            variant="contained"
            disabled={answers.includes(answer.S.split(":::")[1])}
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => {
              navigate("/questions/" + visit + "/" + index);
            }}
          >
            Questionario {answer.S.split(":::")[0]}
          </Button>
        ))}
      <Button
        variant="contained"
        color="warning"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => {
          navigate("/");
        }}
      >
        Volver
      </Button>
    </PageLayout>
  );
}

export default Visit;
